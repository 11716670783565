<template>
  <div>
    <b-card
      no-body
      class="card-employee-task"
    >
      <b-card-header>
        <b-card-title class="text-uppercase">
          <h1>
            <strong>
              <FeatherIcon
                icon="ImageIcon"
                size="30"
              />
              {{ $t('proof_of_delivery') }}
            </strong>
          </h1>
        </b-card-title>
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            lg="4"
            class="mb-2"
          >
            <h3 class="mb-2">
              <strong>{{ $t('pickup_photo') }}</strong>
            </h3>

            <b-avatar
              fluid
              rounded
              :src="order.pickup_photo"
              alt="Pickup Photo"
              size="380px"
              button
              @click="showFullImage(order.pickup_photo)"
            />
          </b-col>
          <b-col
            cols="12"
            lg="4"
            class="mb-2"
          >
            <h3 class="mb-2">
              <strong>{{ $t('dropoff_photo') }}</strong>
            </h3>
            <b-avatar
              fluid
              rounded
              :src="order.delivered_photo"
              alt="Pickup Photo"
              size="380px"
              button
              @click="showFullImage(order.delivered_photo)"
            />
          </b-col>
          <b-col
            cols="12"
            lg="4"
            class="mb-2"
          >
            <h3 class="mb-2">
              <strong>{{ $t('good_return_note') }}</strong>
            </h3>
            <b-avatar
              fluid
              rounded
              :src="order.good_return_photo"
              alt="Pickup Photo"
              size="380px"
              button
              @click="showFullImage(order.good_return_photo)"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <span
              v-if="!order.has_good_return_photo"
              class="d-block text-danger"
            >
              * {{ $t('no_grn_photo_yet') }}
            </span>
            <span
              v-if="!order.has_pickup_photo"
              class="d-block text-danger"
            >
              * {{ $t('no_pickup_photo_yet') }}
            </span>
            <span
              v-if="!order.has_delivered_photo"
              class="d-block text-danger"
            >
              * {{ $t('no_delivery_photo_yet') }}
            </span>
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>

    <b-modal
      id="view"
      ref="view"
      size="lg"
      ok-only
      class="text-center"
    >
      <b-img
        :src="url"
        class="w-100"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody, BImg,
} from 'bootstrap-vue'

export default {
  name: 'ProofDelivery',
  components: {
    BImg,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
  },
  props: {
    order: {
      required: true,
      type: [Object, Array],
    },
  },
  data() {
    return {
      url: null,
    }
  },
  methods: {
    showFullImage(url) {
      this.url = url
      this.$refs.view.show()
    },
  },
}
</script>
