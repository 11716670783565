var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-body"},[_c('ValidationObserver',{ref:"newPortForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.handleAddEditQuotation)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Expected Delivery Date ETA"}},[_c('validation-provider',{attrs:{"vid":"delivery_date","name":"delivery_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"required":true,"placeholder":"Enter delivery date","type":"number","readonly":_vm.readOnly,"disabled":_vm.readOnly,"min":new Date()},model:{value:(_vm.form.delivery_date),callback:function ($$v) {_vm.$set(_vm.form, "delivery_date", $$v)},expression:"form.delivery_date"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Insurance"}},[_c('validation-provider',{attrs:{"vid":"insurance","name":"insurance"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"readonly":_vm.readOnly,"disabled":!_vm.hasInsurance,"placeholder":"Enter insurance"},model:{value:(_vm.form.insurance),callback:function ($$v) {_vm.$set(_vm.form, "insurance", $$v)},expression:"form.insurance"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tax"}},[_c('validation-provider',{attrs:{"vid":"tax","name":"tax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"readonly":_vm.readOnly,"placeholder":"Enter tax"},model:{value:(_vm.form.tax),callback:function ($$v) {_vm.$set(_vm.form, "tax", $$v)},expression:"form.tax"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Quotation"}},[_c('validation-provider',{attrs:{"vid":"quotation","name":"quotation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"readonly":_vm.readOnly,"placeholder":"Enter quotation","required":""},model:{value:(_vm.form.quotation),callback:function ($$v) {_vm.$set(_vm.form, "quotation", $$v)},expression:"form.quotation"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Total"}},[_c('validation-provider',{attrs:{"vid":"total","name":"total"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"","required":"","readonly":""},model:{value:(_vm.computeSum),callback:function ($$v) {_vm.computeSum=$$v},expression:"computeSum"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.form.documents)?_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex justify-content-between mb-[5px]"},[_c('span',[_vm._v(" Quote Document ")])]),(_vm.form.documents.length)?_c('p',{staticClass:"border rounded p-1"},_vm._l((_vm.form.documents),function(doc,index){return _c('span',{key:doc.id},[_c('a',{staticClass:"text-warning",attrs:{"href":doc.path,"target":"_blank"}},[_vm._v(" View quote document # "+_vm._s(index + 1)+" "),_c('br')])])}),0):_c('b-form-input',{attrs:{"value":"No Document","readonly":"","disabled":""}})],1):_vm._e(),(!_vm.form.documents && !_vm.readOnly)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Upload Document"}},[_c('validation-provider',{attrs:{"vid":"quote_document","name":"quote_document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{ref:"myFiles",staticClass:"hidden",attrs:{"id":"file","type":"file","multiple":""},on:{"change":_vm.handleUploadFile}}),_c('label',{staticClass:"form-control broken-border rounded pt-2 pb-3 text-center",attrs:{"for":"file"}},[(_vm.file)?_c('span',_vm._l((_vm.file),function(f,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(f.name)+" "+_vm._s(index + 1 === _vm.file.length ? "" : ", ")+" ")])}),0):_c('span',{staticClass:"d-flex justify-content-center align-items-center"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"UploadIcon"}}),_vm._v(" Upload quote document..")],1)]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e()],1),(!_vm.readOnly)?_c('div',[_c('hr'),_c('b-button',{staticClass:"float-right mr-1",attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('b-spinner',{staticClass:"mr-50 p-0",attrs:{"small":""}}):_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.quotation ? "Update Quotation" : "Add New Quotation")+" ")],1)],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }