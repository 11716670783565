<template>
  <b-card
    no-body
    class="d-flex flex-column h-100"
  >
    <b-card-header>
      <b-card-title class="text-uppercase">
        <h1>
          <strong>
            <FeatherIcon
              icon="MapPinIcon"
              size="30"
            />
            {{ $t('tracking_summary') }}
          </strong>
        </h1>
      </b-card-title>
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <app-timeline>
        <app-timeline-item
          v-for="log in order.logs"
          :key="log.id"
          :title="log.title"
          :subtitle="log.description"
          :time="log.date_added"
          icon="MapPinIcon"
          variant="success"
        />
      </app-timeline>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
  },
  props: {
    order: {
      required: true,
      type: Object,
      default: () => {},
    },
  },
  data() {
    const statusIcons = [
      { value: 0, text: 'Unpaid' },
      { value: 1, text: 'Active', icon: 'CheckCircleIcon' },
      { value: 2, text: 'Assigned SMARTTRUCK', icon: 'ShoppingBagIcon' },
      { value: 3, text: 'Arrived at Sender', icon: 'FlagIcon' },
      { value: 4, text: 'Arrived at Golog', icon: 'HomeIcon' },
      { value: 5, text: 'In Transit', icon: 'FastForwardIcon' },
      { value: 6, text: 'Picked Up by SMARTTRUCK', icon: 'CornerDownRightIcon' },
      { value: 7, text: 'Out for Delivery', icon: 'TruckIcon' },
      { value: 8, text: 'Delivered', icon: 'UserCheckIcon' },
      { value: 9, text: 'Request For Cancellation', icon: 'CoffeeIcon' },
      { value: 10, text: 'Cancelled' },
      { value: 11, text: 'Request For Refund', icon: 'DollarSignIcon' },
    ]

    return {
      statusIcons,
    }
  },
}
</script>

<style>

</style>
