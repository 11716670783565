<template>
  <div class="modal-body">
    <ValidationObserver ref="newPortForm" v-slot="{ passes }">
      <form @submit.prevent="passes(handleAddEditQuotation)">
        <b-row>
          <b-col md="12">
            <b-form-group label="Expected Delivery Date ETA">
              <validation-provider
                v-slot="{ errors }"
                vid="delivery_date"
                name="delivery_date"
              >
                <b-form-datepicker
                  v-model="form.delivery_date"
                  :required="true"
                  placeholder="Enter delivery date"
                  type="number"
                  :readonly="readOnly"
                  :disabled="readOnly"
                  :min="new Date()"
                />

                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Insurance">
              <validation-provider
                v-slot="{ errors }"
                vid="insurance"
                name="insurance"
              >
                <b-form-input
                  v-model="form.insurance"
                  :readonly="readOnly"
                  :disabled="!hasInsurance"
                  placeholder="Enter insurance"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Tax">
              <validation-provider v-slot="{ errors }" vid="tax" name="tax">
                <b-form-input
                  v-model="form.tax"
                  :readonly="readOnly"
                  placeholder="Enter tax"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Quotation">
              <validation-provider
                v-slot="{ errors }"
                vid="quotation"
                name="quotation"
              >
                <b-form-input
                  v-model="form.quotation"
                  :readonly="readOnly"
                  placeholder="Enter quotation"
                  required
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Total">
              <validation-provider v-slot="{ errors }" vid="total" name="total">
                <b-form-input
                  v-model="computeSum"
                  placeholder=""
                  required
                  readonly
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col v-if="form.documents" md="12">
            <div class="d-flex justify-content-between mb-[5px]">
              <span> Quote Document </span>
              <!-- <span v-if="!readOnly">
                <a
                  v-if="!isChangeDocument"
                  href="#"
                  @click="isChangeDocument = true"
                >
                  <span> Add document </span>
                </a>
                <a v-else href="#" @click="isChangeDocument = false">
                  <span> Cancel </span>
                </a>
              </span> -->
            </div>
            <p v-if="form.documents.length" class="border rounded p-1">
              <span v-for="(doc, index) in form.documents" :key="doc.id">
                <a :href="doc.path" target="_blank" class="text-warning">
                  View quote document # {{ index + 1 }} <br />
                </a>
              </span>
            </p>
            <b-form-input v-else value="No Document" readonly disabled />
          </b-col>

          <b-col v-if="!form.documents && !readOnly" md="12" class="">
            <b-form-group label="Upload Document">
              <validation-provider
                v-slot="{ errors }"
                vid="quote_document"
                name="quote_document"
              >
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  class="hidden"
                  multiple
                  @change="handleUploadFile"
                />
                <label
                  class="form-control broken-border rounded pt-2 pb-3 text-center"
                  for="file"
                >
                  <span v-if="file">
                    <span v-for="(f, index) in file" :key="index">
                      {{ f.name }} {{ index + 1 === file.length ? "" : ", " }}
                    </span>
                  </span>
                  <span
                    v-else
                    class="d-flex justify-content-center align-items-center"
                  >
                    <feather-icon icon="UploadIcon" class="mr-1" /> Upload quote
                    document..</span
                  >
                </label>

                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <div v-if="!readOnly">
          <hr />
          <b-button
            variant="primary"
            class="float-right mr-1"
            type="submit"
            :disabled="isLoading"
          >
            <b-spinner v-if="isLoading" class="mr-50 p-0" small />
            <feather-icon v-else icon="SaveIcon" class="mr-50" />
            {{ quotation ? "Update Quotation" : "Add New Quotation" }}
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<!-- eslint-disable arrow-parens -->
<!-- eslint-disable comma-dangle -->
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import axios from "axios";
import moment from "moment";

export default {
  name: "QuotationForm",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    order_id: {
      type: Number,
      required: true,
    },
    quotation: {
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    hasInsurance: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: null,
      isLoading: false,
      form: this.quotation
        ? this.quotation
        : {
            tax: null,
            insurance: null,
            quotation: null,
            total: null,
            quote_document: null,
            delivery_date: moment().format("YYYY-MM-DD"),
            order_id: this.order_id,
          },
    };
  },
  computed: {
    computeSum() {
      const tax = this.form.tax ? parseFloat(this.form.tax) : 0;
      const insurance = this.form.insurance
        ? parseFloat(this.form.insurance)
        : 0;
      const quotation = this.form.quotation
        ? parseFloat(this.form.quotation)
        : 0;
      const val = tax + insurance + quotation;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.form.total = val;
      return val;
    },
  },
  methods: {
    handleUploadFile() {
      this.file = this.$refs.myFiles.files;
    },
    async handleAddEditQuotation() {
      try {
        this.isLoading = true;
        const formData = this.sanitizeData(this.form);
        const response = await this.$http.post(
          this.quotation ? `/quote/${this.quotation.id}` : "/quote",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200 || response.status === 201) {
          this.$emit(
            "success",
            this.quotation ? "Quote Updated" : "New Quote Added",
            this.quotation
              ? "Successfully updated a quote"
              : "Successfully created a quote",
            response.data.data
          );
          this.isLoading = false;
        }
        if (response?.status === 422) {
          this.$refs.newPortForm.setErrors(response.data.errors);
          this.isLoading = false;
        }
      } catch (error) {
        if (error.response?.status === 422) {
          this.$refs.newPortForm.setErrors(response.data.errors);
          this.isLoading = false;
        } else {
          throw new Error("Something went wrong.", error);
        }
      }
    },
    sanitizeData(value) {
      const fullPath = this.$route.fullPath;
      const segments = fullPath.split("/");
      let lastSegmentFromUrl = segments[segments.length - 1];
      const formData = new FormData();
      formData.append("order_id", value.order_id);
      formData.append("tax", value.tax ?? 0);
      formData.append("quotation", value.quotation ?? 0);
      formData.append("total", value.total ?? 0);
      formData.append("insurance", value.insurance ?? 0);
      formData.append("delivery_date", value.delivery_date);
      formData.append(
        "is_chartered",
        lastSegmentFromUrl === "chartered-truck" ? true : false
      );
      if (this.quotation) {
        formData.append("_method", "PATCH");
        formData.append("id", value.id);
      }
      if (this.file) {
        for (let i = 0; i < this.file.length; i++) {
          formData.append("quote_document[]", this.file[i]);
        }
      }

      return formData;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
.broken-border {
  border-style: dashed; /* Change to 'dotted' for a dotted line */
  border-width: 2px; /* Adjust the width as needed */
  padding: 10px; /* Add padding to make it more visible */
}
</style>
