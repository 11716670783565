<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1 class="font-weight-bolder">
          <FeatherIcon
            icon="MapPinIcon"
            size="30"
          />
          {{ $t('order_tracking') }}
        </h1>
      </div>
      <div class="bg-white d-flex align-items-center">
        <div class="bg-primary py-1 px-3 text-center">
          <p class="font-weight-bolder m-0 p-0 h5 text-white">
            {{ $t('order_id') }}
          </p>
        </div>
        <div class="py-1 px-3">
          <p class="font-weight-bolder m-0 p-0 h5">
            {{ order.reference_id }}
          </p>
        </div>
      </div>
    </div>
    <div class="card box-shadow-none mt-2 d-lg-block d-none">
      <div class="card-body">
        <div
          class="my-2 text-center"
          style="position: relative"
        >
          <div style="position: relative; padding-left: 100px;">
            <div class="bg-primary line" />
          </div>
          <div class="d-flex justify-content-between align-items-center ">
            <div class="track d-flex flex-column align-items-center">
              <div
                :class="[
                  'timeline-item-icon',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'rounded-circle',
                  'border p-1 mb-1 icon',
                  order.status.value >= 1 && order.status.value != 10? 'active-tracking border-primary' : 'bg-white']"
              >
                <feather-icon
                  color="#ea5455"
                  size="25"
                  icon="ShoppingCartIcon"
                />
              </div>
              <h6 class="mb-0">
                {{ $t('order_placed') }}
              </h6>
            </div>
            <div class="track d-flex flex-column align-items-center">
              <div
                :class="[
                  'timeline-item-icon',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'rounded-circle',
                  'border p-1 mb-1 icon',
                  order.status.value >= 2 && order.status.value != 10 ? 'active-tracking border-primary' : 'bg-white']"
              >
                <feather-icon
                  color="#ea5455"
                  size="25"
                  icon="LoaderIcon"
                />
              </div>
              <h6 class="mb-0">
                {{ $t('processing') }}
              </h6>
            </div>
            <div class="track d-flex flex-column align-items-center">
              <div
                :class="[
                  'timeline-item-icon',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'rounded-circle',
                  'border p-1 mb-1 icon',
                  order.status.value >= 6 && order.status.value != 10 ? 'active-tracking border-primary' : 'bg-white']"
              >
                <feather-icon
                  color="#ea5455"
                  size="25"
                  icon="InboxIcon"
                />
              </div>
              <h6 class="mb-0">
                {{ $t('picked_up') }}
              </h6>
            </div>
            <div class="track d-flex flex-column align-items-center">
              <div
                :class="[
                  'timeline-item-icon',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'rounded-circle',
                  'border p-1 mb-1 icon',
                  order.status.value >= 7 && order.status.value != 10 ? 'active-tracking border-primary' : 'bg-white']"
              >
                <feather-icon
                  color="#ea5455"
                  size="25"
                  icon="TruckIcon"
                />
              </div>
              <h6 class="mb-0">
                {{ $t('out_for_delivery') }}
              </h6>
            </div>
            <div class="track d-flex flex-column align-items-center">
              <div
                :class="[
                  'timeline-item-icon',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'rounded-circle',
                  'border p-1 mb-1 icon',
                  order.status.value >= 8 && order.status.value != 10 ? 'active-tracking border-primary' : 'bg-white']"
              >
                <feather-icon
                  color="#ea5455"
                  size="25"
                  icon="CheckCircleIcon"
                />
              </div>
              <h6 class="mb-0">
                {{ $t('delivered') }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeliveryProgress',
  props: {
    order: {
      required: true,
      type: [Object, Array],
    },
  },
}
</script>

<style scoped>
.line {
  height: 5px;
  position: absolute;
  top: 33px;
  width: 85%;
}
.track {
  z-index: 1;
  width: 15%;
}
.icon {
  width: 70px;
  height: 70px;
}
.active-tracking {
  background-color: #ea9fad;
}
</style>
