<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <b-form-group>
            <label for="datepicker">{{ $t('choose_date') }}</label>
            <b-input-group>
              <flat-pickr
                id="datepicker"
                v-model="date"
                :placeholder="$t('select_date')"
                :config="{
                  dateFormat: 'Y-m-d',
                  altInput: true,
                  altFormat: 'D F j, Y'
                }"
                @input="setDate"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <label for="area-picker">{{ $t('preferred_area') }}</label>
          <b-form-group>
            <b-form-select
              id="area-picker"
              v-model="state"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              @input="setState"
            >
              <b-form-select-option
                v-for="(item, index) in options"
                :key="index"
                :value="item.value"
              >
                {{ item.title }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <MyTable
      :rows="rows"
      :columns="fields"
      :is-loading="isLoading"
    >
      <template v-slot="{props}">
        <span v-if="props.column.field === 'id'">
          <span
            :class="`text-primary font-weight-bold`"
          >{{ props.row.reference_id }}</span>
        </span>

        <span v-else-if="props.column.field === 'client'">
          <b-img
            :src="props.row.client.logo"
            rounded
            width="75px"
          />
          <span class="ml-1">{{ props.row.client.market.name }}</span>
        </span>

        <span v-else-if="props.column.field === 'dropoff'">
          <span>{{ (props.row.recipient.complete && props.row.recipient) ? props.row.recipient.complete : '-' }}</span>
        </span>

        <span v-else-if="props.column.field === 'status'">
          <span :class="`text-${props.row.status.color_name} font-weight-bold`">{{ props.row.status.text }}</span>
        </span>

        <span v-else-if="props.column.field === 'actions'">
          <b-button
            variant="primary"
            :to="({ name: 'view-po', params: { id: props.row.id, date: props.row.date_added.split(' ')[0] } })"
          >View Order</b-button>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </MyTable>
  </div>
</template>

<script>
/* eslint-disable-next-line */
import MyTable from '@/components/MyTable.vue'
import { mapGetters } from 'vuex'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'HyperMarketOrders',
  components: {
    MyTable,
    flatPickr,
  },
  data() {
    return {
      state: 'all',
      date: new Date().toISOString().slice(0, 10),
      options: [
        { title: 'All Area', value: 'all' },
        { title: 'Kuala Lumpur', value: 'Kuala Lumpur' },
        { title: 'Selangor', value: 'Selangor' },
        { title: 'Perlis', value: 'Perlis' },
        { title: 'Kedah', value: 'Kedah' },
        { title: 'Pulau Pinang', value: 'Pulau Pinang' },
        { title: 'Pahang', value: 'Pahang' },
        { title: 'Perak', value: 'Perak' },
        { title: 'Terengganu', value: 'Terengganu' },
        { title: 'Kelantan', value: 'Kelantan' },
        { title: 'Negeri Sembilan', value: 'Negeri Sembilan' },
        { title: 'Melaka', value: 'Melaka' },
        { title: 'Johor', value: 'Johor' },
        { title: 'Sabah', value: 'Sabah' },
        { title: 'Sarawak', value: 'Sarawak' },
        { title: 'Singapore', value: 'Singapore' },
      ],
      fields: [
        {
          field: 'id',
          label: 'Order ID',
          type: 'number',
          tdClass: 'align-middle text-left pl-2',
          thClass: 'text-left',
        },
        {
          field: 'client',
          label: 'Client',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
        {
          field: 'status',
          label: 'Status',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
        {
          field: 'dropoff',
          label: 'Drop Off',
          sortable: false,
          tdClass: 'align-middle',
        },
        {
          field: 'actions',
          label: 'Actions',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      rows: 'orders/checkOrders',
      isLoading: 'orders/checkLoadingState',
    }),
    defaultDeliveryDateTime() {
      const today = new Date()
      today.setDate(today.getDate() - 7)
      return today
    },
  },
  created() {
    this.getOrders()
  },
  methods: {
    getOrders() {
      const params = `orders_for=smarttruck&delivery_date=${this.date}&state=${this.state}`
      this.$store.dispatch('orders/fetchOrders', { query: params })
    },
    dateDisabled(ymd, date) {
      const mydate = new Date()
      mydate.setDate(mydate.getDate() - 8)
      if (mydate <= date) {
        return false
      }
      return true
    },
    setDate(value) {
      let date
      if (value.length === 0) {
        date = new Date().toISOString().slice(0, 10)
        this.date = date
      } else {
        date = new Date(value).toISOString().split('T')
        /* eslint-disable-next-line */
        this.date = date[0]
        this.getOrders()
      }
    },
    setState(value) {
      this.state = value
      this.getOrders()
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
